<template>
  <div class="wisdom-container">
    <div
      class="wisdom"
      :style="{ 'background-image': `url(${textShow.background})` }"
    >
      <div class="w1120">
        <h2>{{ textShow.title }}</h2>
        <p v-html="textShow.content"></p>
      </div>
    </div>
    <Tab2></Tab2>
    <Tab3 v-if="textShow.flag==1"></Tab3>
  </div>
</template>

<script>
import CustomLinkTab from "../../product/custom-linkTab/index.vue";
import Tab1 from "./tab1/index.vue";
import Tab2 from "./tab2/index.vue";
import Tab3 from "./tab3/index.vue";
export default {
  computed: {
    textShow() {
      this.selectIndex = 0;
      return this.copywritingList[this.$route.params.id - 1];
    },
  },
  data() {
    return {
      copywritingList: [
        {
          title: "智慧城市解决方案",
          content:
            "智慧城市解决方案从智慧管理和智慧服务角度出发，充分利用数字孪生、大数据、<br/>云计算等技术群落，构建数字孪生场景，设立管理运营、能效安防、生态协同三大数字运维板块，打通智慧城市各业务数据孤岛，1:1还原现实城市全要素，实现城市人、事、物的“可视、可管、可控”",
          background: require("@/assets/assest/city-back.png"),
          flag:1,
        },
        {
          title: "智慧文旅解决方案",
          content:
            "通过技术整合线上线下资源，运用多感官交互技术创造深度沉浸式文旅体验<br/>创造场景与内容、技术与产业的跨界融合，打造“智慧文旅数字孪生平台”",
          background: require("@/assets/assest/city-back10.png"),
          flag:1,
        },
        {
          title: "智慧园区解决方案",
          content:
            "整合园区二维、三维数据资源，还原出真实场景，并实时接入数据<br/>管理高效便捷，做到“一张图”看全局，有效提高园区工作效率，提升园区生产力",
          background: require("@/assets/assest/city-back1.png"),
          flag:1,
        },
        {
          title: "智慧工厂解决方案",
          content:
            "通过三维数字孪生技术，在云端再造工厂、车间、设备的全视角实时场景，配合各类<br/>生产计划、产品质量、产量进度、设备状态等管理数据，实时呈现出工厂实际运行状<br/>态，赋能工业大脑",
          background: require("@/assets/assest/city-back2.png"),
          flag:1,
        },
        {
          title: "智慧交通解决方案",
          content:
            "智慧交通解决方案是通过数字孪生的方式，采集交通数据，并纳入到虚拟数字映射中 通过大数据分析、人工智能Ai和交通仿真技术生成交通的优化方案",
          background: require("@/assets/assest/city-back3.png"),
          flag:0,
        },
        {
          title: "智慧能源解决方案",
          content:
            "在现有能源系统的建模仿真和在线监测技术的基础上，利用数字孪生技术进一步涵盖状态感知、边缘计算、智能互联、协议适配、智能分析等技术，为智慧能源系统提供更加丰富和真实的模型，从而全面服务于系统的运行和控制",
          background: require("@/assets/assest/city-back4.png"),
          flag:1,
        },
        {
          title: "基于真实人物外观的数字虚拟人",
          content: "3D写实数字虚拟人，超写实的面部表情、动作、声音<br/>使其更接近真人，带去更自然逼真的沉浸式体验",
          background: require("@/assets/assest/city-back7.png"),
          flag:0,
        },
        {
          title: "基于卡通形象的数字虚拟人",
          content: "2.5D卡通数字虚拟人，随意创造角色丰富、造型多变的虚拟形象<br/>极富有趣味性，带去更加新奇的体验",
          background: require("@/assets/assest/city-back.png"),
          flag:0,
        },
        {
          title: "VR虚拟教育",
          content:
            "传统的科普教育主要依靠理论知识学习，而VR科普教育则是通过虚拟现场的方式，让体验者切身体验到科普教育的理论知识在现场的应用，并且VR的增强现实技术更能让这种理论知识充分融入到虚拟场景之中，更能让体验者有效的吸收及消化知识",
          background: require("@/assets/assest/city-back8.png"),
          flag:1,
        },
        {
          title: "VR职业实训",
          content:
            "搭建三维仿真各类环境，进行多种职业技能模拟培训<br/>通过VR技术让参与者身临其境的进行职业技能互动培训",
          background: require("@/assets/assest/city-back9.png"),
          flag:1,
        },
        // {
        //   title: "VR法治学习机",
        //   content:
        //     "充分挖掘社区资源，抓住当前社会热点及社会、家庭、学校关注的焦点，运用最流行的VR虚拟体验技术，整合垃圾分类、交通安全等法律法规的学习，形成集学习、交流、体验为一体的法治教育平台综合体。",
        //   background: require("@/assets/assest/city-back.png"),
        // },
        {
          title: "云上展厅",
          content:
            "通过三维虚拟展馆设计与3D数字可视化、三维虚拟现实技术融合<br/>为云上展馆提供有效的技术支撑",
          background: require("@/assets/assest/city-back5.png"),
          flag:0,
        },
        {
          title: "企业驾驶舱",
          content:
            "以企业数据为基础，连接企业所有系统的功能，让企业随时掌控人、财、物各类数据，为企业管理者提供战略统筹、战略执行、预警监督的综合性决策体验。",
          background: require("@/assets/assest/city-back6.png"),
          flag:0,
        },
      ],

      list: ["方案优势", "最佳实践"],
      selectIndex: 0,

      componentMap: ["Tab2", "Tab3"],
    };
  },
  components: { CustomLinkTab, Tab1, Tab2, Tab3 },
};
</script>

<style lang="scss" scoped>
.wisdom-container {
  margin-top: 60px;
}
.wisdom {
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  height: calc(100vh - 60px);
  color: #ffffff;
  padding-top: 271px;
  h2 {
    font-weight: 700;
    font-size: 48px;
    line-height: 70px;
  }
  p {
    width: 680px;
    margin-top: 26px;
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
  }
}
</style>
