<template>
  <div class="tab1 w1120">
    <h2>方案优势</h2>
    <div class="content">
      <template v-for="(item, index) in showText" v-if="$route.params.id != 7">
        <div class="item" v-if="index % 2 === 0">
          <div class="icon">
            <img :src="item.img" alt="" />
          </div>
          <div class="text left-margin">
            <h3 v-html="item.title"></h3>
            <p v-html="item.subTitle"></p>
          </div>
        </div>

        <div class="item" v-else>
          <div class="text right-margin">
            <h3 v-html="item.title"></h3>
            <p v-html="item.subTitle"></p>
          </div>
          <div class="icon">
            <img :src="item.img" alt="" />
          </div>
        </div>
      </template>
      <template v-if="$route.params.id == 7">
        <div class="item item-special">
          <div class="icon">
            <img src="@/assets/assest/youshi20.png" alt="" />
          </div>
          <div class="text left-margin">
            <div class="text-content">
              <h3>面部驱动</h3>
              <p>
                通过实时人脸跟踪技术，实现真人与虚拟形象的表情同步
              </p>
            </div>
            <div class="text-content">
              <h3>身体驱动</h3>
              <p>
                通过摄像头输入，即可实时驱动虚拟形象进行同步动作
              </p>
            </div>
            <div class="text-content">
              <h3>手势识别</h3>
              <p>
                通过手势识别，可以驱动虚拟形象完成相关动作
              </p>
            </div>
          </div>
        </div>
        <div class="item">
          <div class="text right-margin">
            <h3>制作成本低</h3>
            <p>
              基于更成熟的虚拟人技术，从3d建模、动作捕捉、渲染<br />剪辑、合片等流程逐步实现自动化、实时化和智能化，相比真人表演具有更自由的发挥空间，创作内容和延展空间得到释放，大幅提升内容制作效率，极大地降低生产成本
            </p>
          </div>
          <div class="icon">
            <img src="@/assets/assest/youshi21.png" alt="" />
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    showText() {
      return this.copywritingList[this.$route.params.id - 1];
    },
  },
  data() {
    return {
      copywritingList: [
        //智慧城市
        [
          {
            title: "现实世界的全维度可视化",
            subTitle:
              "利用物理模型、运行历史等数据，集成多学科、多物理量 多尺度、多概率的仿真，实现在虚拟空间中的完全映射",
            img: require("@/assets/assest/youshi1.png"),
          },
          {
            title: "快速、简单实现万物联网改造和接入",
            subTitle:
              "从宏观到微观的全维度对象数字化<br/> 并还原关联关系，实现多层次展示",
            img: require("@/assets/assest/youshi2.png"),
          },
          {
            title: "基于数据中台的丰富管理组件<br/>支撑城市级物联网运营",
            subTitle:
              "将全量实时数据和虚拟空间重新耦合<br/>真正完成对物理世界的实时管控",
            img: require("@/assets/assest/youshi3.png"),
          },
        ],
        //智慧文旅
        [
          {
            title:
              "科学的产业规划<span style='margin-left:16px'>预先的部署呈现</span>",
            subTitle:
              "对文旅景区的开发建设进行综合统筹规划，从顶层设计开始，挖掘地方历史和特色，提炼出鲜明的文化主题，进行基础配套设施建设。这些内容在数字孪生平台上进行预先的部署呈现，并作为科学产业规划的依据",
            img: require("@/assets/assest/youshi22.png"),
          },
          {
            title:
              "打通线上和线下<span style='margin-left:16px'>提升服务与品质</span>",
            subTitle:
              "利用数字孪生技术、新超级平台和生态体系，将线下实体运营和线上虚拟导览打通，让每个文化景点的运营更加智慧化、智能化、数字化，提升景区服务和品质",
            img: require("@/assets/assest/youshi23.png"),
          },
          {
            title:
              "精准呈现实景<span style='margin-left:16px'>帮助拓客引流</span>",
            subTitle:
              "将整个旅游景点进行1:1还原，并且采用实时渲染技术，将未来景区的景观节点、光照以及天气效果在建设阶段就能向游客进行精准呈现，同时可在后期运营阶段接入运营数据，打造智慧文旅景点",
            img: require("@/assets/assest/youshi24.png"),
          },
        ],
        //智慧园区
        [
          {
            title: "“一张图”看全局",
            subTitle:
              "数字孪生技术以整体复刻的形式，将现实场景展现给管理<br/>用户，做到“一张图”看全局，从更高的维度来运筹帷幄<br/>管理决策、交互展示",
            img: require("@/assets/assest/youshi4.png"),
          },
          {
            title: "打造信息一体化平台",
            subTitle:
              "通过3D可视化融合园区各领域大数据资源，对园区安防、<br/>资产、基础设施、能效、环境等指标进行综合监测分析<br/>并通过数据传输，实现设备远程控制与智能化感知<br/>打造智能监管一体化平台",
            img: require("@/assets/assest/youshi5.png"),
          },
          {
            title: "场景真实，交互感强",
            subTitle:
              "数字孪生3D可视化技术，让整个园区鸟瞰参观、<br/>3D沉浸式手动或自动漫游成为现实<br/>结合地图导览功能，让人充满探索欲和交互感",
            img: require("@/assets/assest/youshi6.png"),
          },
        ],
        //智慧工厂
        [
          {
            title: "全面提升工厂运营效率",
            subTitle:
              "依托先进的3D可视化、数字孪生、VR虚拟现实、web3d仿真和AI等技术，原比例模拟还原工厂全域的地理、环境设备、管线、监控、生产线和人员等全要素，为管理者提供在生产环境中感知、分析信息的集约平台",
            img: require("@/assets/assest/youshi7.png"),
          },
          {
            title: "降低实验测试成本",
            subTitle:
              "3D可视化、数字孪生、VR虚拟现实、web3d仿真和AI等技术，可以虚拟数字化产品模型，对其进行仿真测试和验证，以更低的成本做更多的样机，极大降低实验测试成本",
            img: require("@/assets/assest/youshi8.png"),
          },
        ],
        //智慧交通
        [
          {
            title: "全面提高交通资源配置效率<br/>及安全运转状态",
            subTitle:
              "实现道路基础设施生命周期的动态监测，通过数据全域标识、状态精准感知、数据实时分析、模型科学决策、智能精准执行，同时可以实现交通的模拟、监控、诊断、预测和控制，解决交通规划、设计、建设、管理、服务中的复杂性和不确定性问题，为道路通行诊断和交通管理决策提供精确依据",
            img: require("@/assets/assest/youshi9.png"),
          },
          {
            title: "智慧交通创新的关键支撑",
            subTitle:
              "数字孪生技术通过与物理世界建立底层关联，将物理世界动态实时的映射到虚拟世界当中，并依据当前的交通态势和行为做预测",
            img: require("@/assets/assest/youshi10.png"),
          },
        ],
        //智慧能源
        [
          {
            title: "完美映射智慧能源系统",
            subTitle:
              "数字孪生技术可以充分利用精细化物理模型、智能传感器数据、运维历史等数据，集成电、磁、热、流体等多学科多物理量、多尺度、多概率的仿真过程，在虚拟空间中完成对智慧能源系统的映射",
            img: require("@/assets/assest/youshi11.png"),
          },
          {
            title: "反映智能设备的全周期生命过程",
            subTitle:
              "数字孪生实例的反映对应智能设备的全生命周期过程，实时更新与动态演化，进而实现对智慧能源系统的真实映射",
            img: require("@/assets/assest/youshi12.png"),
          },
          {
            title: "衍生丰富应用功能",
            subTitle:
              "例如：数字孪生体与数值天气预报结合，可在线模拟极端气象条件下综合能源系统故障和恢复，给出隔离故障和恢复供能的最优方案；也可在云端利用数字孪生体开展强化学习训练，获得综合能源系统最优调度运行策略，进而下载部署于实际系统中，持续改善综合能源开发和利用成效",
            img: require("@/assets/assest/youshi13.png"),
          },
        ],
        //写实3D虚拟人
        [{

        }],
        //卡通2.5D虚拟人
        [
          {
            title: "更有趣味性",
            subTitle:
              "实现了人物形象、声线更风趣幽默的还原<br/>相较于真实人物外观的虚拟人物或二次元原生虚拟IP<br/>根据真人定制的2.5D卡通形象虚拟人，被赋予了更多趣味 ",
            img: require("@/assets/assest/youshi1.png"),
          },
          {
            title: "制作周期短",
            subTitle: "相较于3D虚拟人，创作内容和延展空间得到释放<br/>制作周期短，大幅提升制作效率，极大地降低生产成本",
            img: require("@/assets/assest/youshi2.png"),
          },
        ],
        //VR虚拟教育
        [
          {
            title: "用于知识科普",
            subTitle:
              "增强教育时效，全沉浸式体验，身临其境体验教学<br/>化被动为主动，亲身体验各类科教场景",
            img: require("@/assets/solution/teach.png"),
          },
          {
            title: "用于灾害逃生演练",
            subTitle:
              "VR技术打破时空限制，受训者可以在不同时间、不同地点<br/>进行集中演练，并快速取得演练结果，以进行评估和改进",
            img: require("@/assets/solution/teach1.png"),
          },
          {
            title: "用于基础教育",
            subTitle:
              "寓教于乐，科技、便捷的情景再现<br/>让学习人员更加深刻了解更多基础知识",
            img: require("@/assets/solution/teach2.png"),
          },
        ],
        //VR职业实训
        [
          {
            title: "技能培训更高效",
            subTitle:
              "场景化、智能化的沉浸式智能化教学能够帮助学员强化记忆力，增强学习效率。学员在VR中进行模拟，既能体验不同的场景，还降低了成本，可以随时随地进行反复模拟学习，一次购买多次使用，性价比高",
            img: require("@/assets/solution/learn1.png"),
          },
          {
            title: "安全生产培训更有效",
            subTitle:
              "采用人物互动的方式，以旁观者与受害者的角度将参与者置身于模拟场景中，使他们能够“感同身受”，提高工厂安全生产培训成效",
            img: require("@/assets/solution/learn-2.png"),
          },
        ],
        // //VR法治学习机
        // [
        //   {
        //     title: "提高培训成效",
        //     subTitle:
        //       "采用人物互动的方式，以旁观者与受害者的角度将参与者置身于模拟场景中，使他们能够“感同身受”；",
        //     img: require("@/assets/assest/youshi1.png"),
        //   },
        //   {
        //     title: "降低培训成本",
        //     subTitle:
        //       "可以随时随地进行反复模拟学习，一次购买多次使用，性价比高；",
        //     img: require("@/assets/assest/youshi2.png"),
        //   },
        //   {
        //     title: "打破多种限制",
        //     subTitle:
        //       "VR技术打破时空限制，受训者可以在不同时间和地点进行集中演练，并快速取得演练结果，进行评估和改进。",
        //     img: require("@/assets/assest/youshi3.png"),
        //   },
        //   {
        //     title: "提高学习的积极性",
        //     subTitle: "互动性教学，增多体验者的参与性与实操性，提高学习兴趣。",
        //     img: require("@/assets/assest/youshi3.png"),
        //   },
        // ],
        //云上展厅
        [
          {
            title: "云上看展体验感好",
            subTitle:
              "按照要求布局云上展厅，实现智慧展馆和虚拟现实的融合<br/>运用三维虚拟现实技术，快速打造线上虚拟展馆展示<br/>让观众实现在线云上看展，体验感极好",
            img: require("@/assets/assest/youshi14.png"),
          },
          {
            title: "打破地域和时间局限",
            subTitle:
              "采用三维全景虚拟化技术手段，展示云上数字展馆，较之三维建模技术等其他虚拟展示手段，它具备制作周期短、方便更新、成本低等优点，赋能云上展会多场景经营",
            img: require("@/assets/assest/youshi15.png"),
          },
        ],
        //企业驾驶舱
        [
          {
            title: "数据准确、配套治理",
            subTitle:
              "企业驾驶舱利用数据治理体系框架，科学设计数据架构、配套指标管理体系，从而保证数据准确性",
            img: require("@/assets/assest/youshi16.png"),
          },
          {
            title: "覆盖监管、业务全面",
            subTitle:
              "企业驾驶舱满足企业统一监控、统一预警要求<br/>同时覆盖全业务线",
            img: require("@/assets/assest/youshi17.png"),
          },
          {
            title: "实时监控、动态预警 ",
            subTitle:
              "捕捉数据的流动价值<br/>企业驾驶舱能够实现实时监控和动态预警",
            img: require("@/assets/assest/youshi18.png"),
          },
          {
            title: "展示多样、直观友好",
            subTitle:
              "在保证功能完善的基础上，凭借多种数据分析、可视化技术和专业UI团队的加持，企业驾驶舱对交互方式及界面展示进行了优化，大幅提升用户的使用体验",
            img: require("@/assets/assest/youshi19.png"),
          },
        ],
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.tab1 {
  background-color: #ffffff;
  padding-top: 62px;
  h2 {
    font-weight: 500;
    font-size: 40px;
    line-height: 56px;
    color: #333439;
    text-align: center;
  }
  .content {
    padding-top: 50px;
    width: 100%;
    .left-margin {
      margin-left: 124px;
    }
    .right-margin {
      margin-right: 124px;
    }
    .item {
      display: flex;
      margin-bottom: 88px;
      .icon {
        width: 644px;
        height: 360px;
        img {
          filter: drop-shadow(-16px -16px 40px #ffffff)
            drop-shadow(16px 4px 64px rgba(18, 61, 101, 0.2));
        }
      }
      .text {
        display: flex;
        justify-content: center;
        flex-direction: column;
        width: 355px;
        height: 360px;
        h3 {
          font-weight: 400;
          font-size: 22px;
          line-height: 32px;
          color: #333439;
          margin-bottom: 40px;
        }
        p {
          font-weight: 400;
          font-size: 14px;
          line-height: 25px;
          color: #5c5b5b;
        }
      }
    }
    .item-special {
      .icon {
        .img-text {
          font-weight: 400;
          font-size: 22px;
          line-height: 32px;
          position: absolute;
          top: 227px;
          right: 196px;
        }
      }
      .text {
        display: flex;
        justify-content: space-around;

        h3 {
          margin-bottom: 12px;
        }
      }
    }
  }
}
</style>
