<template>
  <div class="backColor">
    <div class="tab w1120">
      <h2>最佳实践</h2>
      <h3>{{ showText.title }}</h3>
      <div class="textArea">
        <p v-html="showText.subTitle">
        </p>
      </div>
      <div class="icon">
        <img :src="showText.img" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    showText() {
      return this.copywritingList[this.$route.params.id - 1];
    },
  },
  data() {
    return {
      copywritingList: [
        //智慧城市解决方案
        {
          title: "兰州碧桂园元宇宙社区",
          subTitle:
            "从多维度展示兰州碧桂园元宇宙社区，兰州碧桂园元宇宙社区解决方案通过对兰州碧桂园实景的 1:1 比例还原 3D 建模，打造场景展示、运营管理、安防管理为一体的综合型管理平台，融合智慧物业、智慧车场、智慧灯杆、环境监测等实时数据，为兰州碧桂园数字化提供全要素、全场景、全周期的智慧服务管理平台。从而满足在日常管理、决策分析等多维度的需要，实现“智慧管理、一屏总览、炫酷展示、及时响应”的一站式数字孪生解决方案。",
          img: require("@/assets/background/notebook.png"),
        },
        //智慧文旅解决方案
        {
          title: "拈花湾智慧文旅",
          subTitle:
            "打通公共数据，迅速上线景区动态监测等应用，实现高效便捷的管理；联通城市大数据系统，实现人流监测、预警、通知；在旅游预约、停车、入园等<br/>多环节，实现精准反馈、无感交互、无形核验，更好地实现人与景区、文与旅、求知与休闲、学习与旅游的深度融合，助力景区宣传推广",
          img: require("@/assets/background/notebook4.png"),
        },
        //智慧园区解决方案
        {
          title: "雪浪小镇智慧园区",
          subTitle:
            "依托数字孪生技术，对园区的人、事、物等对象进行数字化建模和重构。基于三维GIS平台的智慧园区信息化系统获取雪浪小镇的管理信息<br/>并以智慧化的管理支撑平台整合各种信息，实现园区内的信息互联互通，从而实现园区的全面感知与智慧管理",
          img: require("@/assets/background/notebook1.png"),
        },
        //智慧工厂解决方案        
        {
          title: "华伟液压工厂大脑数字孪生解决方案",
          subTitle:
            "将工厂车间高精度三维模型、工艺流程、设备属性、设备实时数据，以及工厂运营管理数据集中到一个空间中，创造出一个数字模型<br/>直观地展示生产车间的工艺流程，实现车间生产的远程控制管理，同时提供完整的、高附加值的产品解决方案，实现企业的智能化与精细化管理",
          img: require("@/assets/background/notebook2.png"),
        },
        // 智慧交通
        {
          title: "兰州碧桂园元宇宙社区",
          subTitle:
            "从多维度展示兰州碧桂园元宇宙社区，兰州碧桂园元宇宙社区解决方案通过对兰州碧桂园实景的 1:1 比例还原 3D 建模，打造场景展示、运营管理、安防管理为一体的综合型管理平台，融合智慧物业、智慧车场、智慧灯杆、环境监测等实时数据，为兰州碧桂园数字化提供全要素、全场景、全周期的智慧服务管理平台。从而满足在日常管理、决策分析等多维度的需要，实现“智慧管理、一屏总览、炫酷展示、及时响应”的一站式数字孪生解决方案。",
          img: require("@/assets/background/notebook.png"),
        },
        //智慧能源
        {
          title: "兰州智慧城市供热数字孪生解决方案",
          subTitle:
            "运用丰富的3D组态，镜像兰州供热全流程系统。三维可视化系统更接近于现实中的真实的楼宇、管网和设备<br/>可以在供热期间通过系统实时监视热网支线的运行参数，及时对生产运行情况进行分析、管控，实现快速响应、快速处理、业务全流程监管",
          img: require("@/assets/background/notebook3.png"),
        },
        //写实3D虚拟人
        {
          title: "兰州碧桂园元宇宙社区",
          subTitle:
            "从多维度展示兰州碧桂园元宇宙社区，兰州碧桂园元宇宙社区解决方案通过对兰州碧桂园实景的 1:1 比例还原 3D 建模，打造场景展示、运营管理、安防管理为一体的综合型管理平台，融合智慧物业、智慧车场、智慧灯杆、环境监测等实时数据，为兰州碧桂园数字化提供全要素、全场景、全周期的智慧服务管理平台。从而满足在日常管理、决策分析等多维度的需要，实现“智慧管理、一屏总览、炫酷展示、及时响应”的一站式数字孪生解决方案。",
          img: require("@/assets/background/notebook.png"),
        },
        //卡通2.5D虚拟人
        {
          title: "兰州碧桂园元宇宙社区",
          subTitle:
            "从多维度展示兰州碧桂园元宇宙社区，兰州碧桂园元宇宙社区解决方案通过对兰州碧桂园实景的 1:1 比例还原 3D 建模，打造场景展示、运营管理、安防管理为一体的综合型管理平台，融合智慧物业、智慧车场、智慧灯杆、环境监测等实时数据，为兰州碧桂园数字化提供全要素、全场景、全周期的智慧服务管理平台。从而满足在日常管理、决策分析等多维度的需要，实现“智慧管理、一屏总览、炫酷展示、及时响应”的一站式数字孪生解决方案。",
          img: require("@/assets/background/notebook.png"),
        },
        //VR虚拟教育
        {
          title: "VR八礼四仪",
          subTitle:"互动答题，现学现用，借助动画情景展现八礼四仪<br/>增加趣味性，增加吸引力，让参与者更轻松理解八礼四仪的内容",
          img: require("@/assets/solution/teach3.png"),
        },
        //VR职业实训
        {
          title: "VR地铁模拟驾驶",
          subTitle:
            "提供高还原度的列车驾驶视景、声音和运动等，逼真的驾驶操作环境，以及正常运行条件下和故障条件下列车操纵功能仿真<br/>根据地铁驾驶模拟训练的需求，内置多种故障处理流程评估仿真科目，以及多种紧急情况处置训练科目",
          img: require("@/assets/solution/learn-bg.png"),
        },
        //云上展厅
        {
          title: "兰州碧桂园元宇宙社区",
          subTitle:
            "从多维度展示兰州碧桂园元宇宙社区，兰州碧桂园元宇宙社区解决方案通过对兰州碧桂园实景的 1:1 比例还原 3D 建模，打造场景展示、运营管理、安防管理为一体的综合型管理平台，融合智慧物业、智慧车场、智慧灯杆、环境监测等实时数据，为兰州碧桂园数字化提供全要素、全场景、全周期的智慧服务管理平台。从而满足在日常管理、决策分析等多维度的需要，实现“智慧管理、一屏总览、炫酷展示、及时响应”的一站式数字孪生解决方案。",
          img: require("@/assets/background/notebook.png"),
        },
        //企业驾驶舱
        {
          title: "兰州碧桂园元宇宙社区",
          subTitle:
            "从多维度展示兰州碧桂园元宇宙社区，兰州碧桂园元宇宙社区解决方案通过对兰州碧桂园实景的 1:1 比例还原 3D 建模，打造场景展示、运营管理、安防管理为一体的综合型管理平台，融合智慧物业、智慧车场、智慧灯杆、环境监测等实时数据，为兰州碧桂园数字化提供全要素、全场景、全周期的智慧服务管理平台。从而满足在日常管理、决策分析等多维度的需要，实现“智慧管理、一屏总览、炫酷展示、及时响应”的一站式数字孪生解决方案。",
          img: require("@/assets/background/notebook.png"),
        }
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.tab {
  height: 1046px;
  padding-top: 65px;
  h2 {
    font-weight: 500;
    font-size: 40px;
    line-height: 56px;
    color: #333439;
    text-align: center;
  }
  h3 {
    font-weight: 400;
    font-size: 22px;
    line-height: 32px;
    text-align: center;
    margin-top: 32px;
    color: #333439;
  }
  .textArea {
    margin-top: 24px;
    width: 100%;
    display: flex;
    justify-content: center;
    p {
      width: 934px;
      font-weight: 350;
      font-size: 14px;
      line-height: 25px;
      color: #5c5b5b;
      text-align: center;
    }
  }
  .icon {
    width: 100%;
    height: 640px;
    margin-top: 56px;
  }
}
</style>
