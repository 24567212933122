<template>
  <div class="customtab">
    <div
      class="item"
      :class="selectIndex === index ? 'selected' : ''"
      v-for="(item, index) in list"
      @click="changeSelectIndex(index)"
    >
      {{ item }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    list: Array,
    selectIndex: Number,
  },
  data() {
    return {};
  },
  methods: {
    changeSelectIndex(index) {
      this.$emit("update:selectIndex", index);
    },
  },
};
</script>

<style lang="scss" scoped>
.customtab {
  width: 100vw;
  height: 60px;
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #ebebeb;
  .item {
    cursor: pointer;
    width: 373px;
    height: 100%;
    font-weight: 400;
    font-size: 22px;
    line-height: 27px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 5px;
  }
  .selected {
    color: #357aff;
    border-bottom: 2px solid #357aff;
  }
}
</style>
